<script setup lang="ts">
import { getParam } from '@/lib/utils';
import { store } from '@/store/store';
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const loader = ref(true);
const router = useRouter();
const route = useRoute();

onMounted(async () => {
  const response = await axios.post('/loginUsingInterimToken', {
    token: route.params.token
  }) as any;

  loader.value = false;

  if (response.status === 'success') {
    const token = response.data.token;
    const targetPid = response.data.pid;

    localStorage.setItem('token', token);
    axios.defaults.headers['authorization'] = 'Bearer ' + token;

    const patients = await axios.post('/getPatients') as any;

    if (patients.data.length) {
      store.members = patients.data;
      const selectedPatient = patients.data.find((patient: any) => patient.pid === targetPid);
      store.setSelectedPatient(selectedPatient);
      store.login();

      setTimeout(() => {
        loader.value = false;
        router.push({ name: 'details', params: { domain: getParam(route) } });
      }, 300);
    } else {
      store.link = 'login';
      setTimeout(() => {
        loader.value = false;
        router.push({ name: 'login', params: { domain: getParam(route) } });
      }, 300);
    }
  }
})
</script>

<script lang="ts">
export default {
    name: 'Delegate'
}
</script>

<template>
  <div v-if="loader" class="mt-16">
    <Loader2 class="w-10 h-10 mx-auto animate-spin " />
    <div class="mt-3 text-gray-500 text-center">Signing you in...</div>
  </div>
</template>
