<script setup lang="ts">
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useGooglePlaces } from '@/lib/useGooglePlaces';
import { vAutoAnimate } from '@formkit/auto-animate/vue';

const {
  country,
  province,
  city,
  postal_code,
  address
} = useGooglePlaces();
</script>

<template>
  <FormField name="country" v-slot="{ handleChange, handleBlur }" v-model:model-value="country" class="hidden">
    <input type="hidden" v-model="country" @change="handleChange" @blur="handleBlur" />
  </FormField>
  <FormField v-slot="{ componentField }" name="province" v-model:model-value="province"
    :validate-on-model-update="true">
    <FormItem v-auto-animate>
      <FormControl>
        <div class="grid gap-1.5">
          <Label for="province">Province</Label>
          <Select id="province" v-bind="componentField" :disabled="true">
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent class="max-h-[40vh]">
              <SelectItem value="BC">British Columbia</SelectItem>
              <SelectItem value="ON">Ontario</SelectItem>
              <SelectItem value="MB">Manitoba</SelectItem>
              <SelectItem value="NU">Nunavut</SelectItem>
              <SelectItem value="QC">Quebec</SelectItem>
              <SelectItem value="NT">Northwest Territories</SelectItem>
              <SelectItem value="AB">Alberta</SelectItem>
              <SelectItem value="SK">Saskatchewan</SelectItem>
              <SelectItem value="YT">Yukon</SelectItem>
              <SelectItem value="NL">Newfoundland and Labrador</SelectItem>
              <SelectItem value="NB">New Brunswick</SelectItem>
              <SelectItem value="NS">Nova Scotia</SelectItem>
              <SelectItem value="PE">Prince Edward Island</SelectItem>
              <SelectItem value="US State">US State</SelectItem>
              <SelectItem value="Outside Canada and USA">Outside Canada and USA</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>
  <FormField v-slot="{ componentField }" name="address" v-model:model-value="address">
    <FormItem v-auto-animate>
      <FormControl>
        <div class="grid gap-1.5">
          <Label for="address">Address</Label>
          <Input id="address" v-bind="componentField" />
        </div>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>
  <FormField v-slot="{ componentField }" name="city" v-model:model-value="city">
    <FormItem v-auto-animate>
      <FormControl>
        <div class="grid gap-1.5">
          <Label for="city">City</Label>
          <Input id="city" type="text" v-bind="componentField" />
        </div>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>
  <FormField v-slot="{ componentField }" name="postal_code" v-model:model-value="postal_code">
    <FormItem v-auto-animate>
      <FormControl>
        <div class="grid gap-1.5">
          <Label for="postal_code">Postal Code</Label>
          <Input id="postal_code" type="text" v-bind="componentField" />
        </div>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>
</template>