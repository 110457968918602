<script setup lang="ts">
import { store } from '@/store/store';
import { Button } from '@/components/ui/button'
import { useRoute } from 'vue-router';
import axios from 'axios';
import { computed, ref, watch } from 'vue';
import { getParam, bucketTime, convertServerDateToLocalDate, isToday } from '@/lib/utils'

interface AppointmentData {
    day: string;
    time: string;
    doctor?: {
        name?: string;
        avatar?: string;
    };
    pid: string;
}

const route = useRoute();
const appID = computed(() => route.query.appID);
const data = ref<AppointmentData>({} as AppointmentData);
const noClick = ref(true);

const displayDate = ref({
    weekday: '',
    monthDay: '',
    time: ''
});

function convertToLocalDateAndTime(): void {
    const date = convertServerDateToLocalDate(`${data.value.day} ${data.value.time}`);
    
    displayDate.value.weekday = isToday(date) ? 'Today' : 
        date.toLocaleString('en-US', { weekday: 'short' });
    
    displayDate.value.monthDay = date.toLocaleString('en-US', { 
        month: 'short', 
        day: 'numeric' 
    });

    const [time, period] = date.toLocaleString('en-US', { 
        hour: 'numeric', 
        minute: 'numeric' 
    }).split(' ');
    
    displayDate.value.time = `${bucketTime(time)} ${period}`;
}

async function fetchAppointmentData(): Promise<void> {
    axios.post('/getAppointment', { appID: appID.value }).then((res: any) => {
        if (res.status == 'success') {
            updateStoreData(res);
            convertToLocalDateAndTime();
            noClick.value = false;
        }
    });
}

function updateStoreData(response: any): void {
    data.value = response.data;
    store.setBookedApp(response.data);
    store.app.msg = response.msg;
    store.setSelectedPatient(store.members.find(x => x.pid === response.data.pid));
}

watch(
    () => store.members, 
    (members) => {
        if (members.length && route.name === 'success') {
            fetchAppointmentData();
        }
    },
    {immediate: true}
);
</script>

<script lang="ts">
import { alternativeSuccessURL } from '@/store/features';

export default {
    beforeRouteEnter(__to, __from, next) {
        if (alternativeSuccessURL.value) {
            window.location.href = alternativeSuccessURL.value
            return;
        }

        next();
    }
}
</script>

<template>
    <div class="text-center my-auto">
        <i class="isax isax-tick-circle5 text-[60px] text-teal-600 rounded-full"></i>
        <h1 class="text-gray-900 font-bold text-[2rem] mt-3">
            Your appointment has been booked successfully.
        </h1>
        
        <div v-if="displayDate.weekday && displayDate.monthDay && displayDate.time" 
             class="text-gray-800 font-medium text-2xl mt-3">
            {{ `${displayDate.weekday}, ${displayDate.monthDay}, ${displayDate.time}` }}
        </div>
        
        <div v-if="data.doctor?.name" class="flex items-center mt-3 justify-center">
            <img v-if="data.doctor?.avatar" :src="data.doctor?.avatar" :alt="data.doctor?.name" class="w-7 h-7 rounded-full mr-3">
            <div class="text-gray-500">{{ data.doctor?.name }}</div>
        </div>
        <Button 
        size="lg" 
        class="mt-[60px] w-full text-lg" 
        :class="{'pointer-events-none opacity-50': noClick}" 
        as-child>
            <RouterLink :to="{name: 'manage_appointment', query: {appID}, params: { domain: getParam(route) }}">
                Modify appointment
            </RouterLink>
        </Button>
        <Button variant="ghost" size="lg" class="mt-3 w-full text-lg font-bold text-gray-700 border border-gray-300" :class="{'pointer-events-none opacity-50': noClick}" as-child>
            <RouterLink :to="{name: 'who', params: { domain: getParam(route) }}">
                <i class="isax isax-add mr-1 text-2xl"></i>
                Book a new appointment
            </RouterLink>
        </Button>
    </div>
</template>
