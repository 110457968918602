<script setup lang="ts">
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, watch } from 'vue'

const { isDisabled = false } = defineProps<{
  isDisabled?: boolean
}>();

const defaultMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const selectableYears = computed(() => {
  const currentYear = new Date().getFullYear();
  const years: number[] = [];
  for (let i = 0; i < 100; i++) {
    const year = currentYear - i;
    years.push(year);
  }
  return years;
})

function isCurrentYear(year: string) {
  return parseInt(year, 10) === new Date().getFullYear();
}

function getMaxMonth(year: string) {
  return isCurrentYear(year) ? new Date().getMonth() + 1 : 12;
}

const year = useFieldValue<string>('year');
const month = useFieldValue<string>('month');
const day = useFieldValue<string>('day');

const setDay = useSetFieldValue('day');
const setMonth = useSetFieldValue('month');

const daysInMonth = computed(() => {
  if (!year.value || !month.value) return 31;

  if (isCurrentYear(year.value)) {
    const isCurrentMonth = parseInt(month.value, 10) === getMaxMonth(year.value);
    if (isCurrentMonth) {
      return new Date().getDate();
    }
  }
  
  return new Date(parseInt(year.value), parseInt(month.value), 0).getDate();
})

const selectableMonths = computed(() => {
  if (isCurrentYear(year.value)) {
    const maxMonth = getMaxMonth(year.value);

    if (month.value) {
      if (parseInt(month.value, 10) > maxMonth) {
        setMonth('', false);
      }
    }

    return defaultMonths.slice(0, maxMonth);
  }

  return defaultMonths;
})

watch([year, month, daysInMonth], () => {
  if (day.value) {
    const maxDays = daysInMonth.value;
    if (parseInt(day.value) > maxDays) {
      setDay('', false);
    }
  }
})
</script>

<template>
  <div class="date-container">
    <div class="w-[30%]">
      <FormField v-slot="{ componentField }" name="year" :validate-on-model-update="true">
        <FormItem v-auto-animate>
          <FormControl>
            <Select id="year" v-bind="componentField" :disabled="isDisabled">
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem v-for="year in selectableYears" :key="year" :value="String(year)">
                  {{ year }}
                </SelectItem>
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </div>
    <div class="w-[40%]">
      <FormField v-slot="{ componentField }" name="month" :validate-on-model-update="true">
        <FormItem v-auto-animate>
          <FormControl>
            <Select id="month" v-bind="componentField" :disabled="isDisabled">
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem v-for="n in 12" :value="String(n).padStart(2, '0')" :key="n">
                  {{ String(n).padStart(2, '0') + ' - ' + selectableMonths[n - 1] }}
                </SelectItem>
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </div>
    <div class="w-[30%]">
      <FormField v-slot="{ componentField }" name="day" :validate-on-model-update="true">
        <FormItem v-auto-animate>
          <FormControl>
            <Select id="day" v-bind="componentField" class="w-full" :disabled="isDisabled">
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem v-for="n in daysInMonth" :value="String(n).padStart(2, '0')">{{
                  String(n).padStart(2, '0') }}</SelectItem>
              </SelectContent>
            </Select>
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </div>
  </div>
</template>