import { ref } from "vue"

function useFormError() {
  const formError = ref<Nullable<string>>(null)
  
  function setFormError(input: string): void {
    formError.value = input
  }

  function clearFormError(): void {
    if (formError.value) {
        formError.value = null
    }
  }

  return {
    formError,
    setFormError,
    clearFormError
  }
}

export default useFormError
