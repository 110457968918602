<script setup lang="ts">
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import DateOfBirth from '@/components/date-of-birth/DateOfBirth.vue'
import { schema as dateOfBirthSchema } from '@/components/date-of-birth/schema'
import { Loader2 } from 'lucide-vue-next'
import { InferOutput, useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { store } from '@/store/store'
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getParam } from '@/lib/utils'
import { FormError, useFormError } from '@/components/form-error'

const route = useRoute();
const router = useRouter();

const loader = ref(false)
const formSchema = toTypedSchema(
    z.object({
        name: z.string().min(2, { message: 'Minimum 2 characters.' }),
        family: z.string().min(2, { message: 'Minimum 2 characters.' }),
        gender: z.string()
    })
    .merge(dateOfBirthSchema)
)

const { handleSubmit } = useForm({
    validationSchema: formSchema,
})

const { formError, setFormError, clearFormError } = useFormError()

const onSubmit = handleSubmit(
    (formValues) => {
        if (loader.value) {
            return;
        }

        loader.value = true;
        storeNewPatient({
            name: formValues.name,
            family: formValues.family,
            gender: formValues.gender,
            year: formValues.year,
            month: formValues.month,
            day: formValues.day
        })

        setTimeout(function () {
            loader.value = false;
            router.push({ name: 'address', params: { domain: getParam(route) } });
        }, 300);
    }, (errors) => setFormError(errors.toString())
);

function storeNewPatient(formValues: InferOutput<typeof formSchema>) {
    store.newPatient.name = formValues.name;
    store.newPatient.family = formValues.family;
    store.newPatient.gender = formValues.gender;
    store.newPatient.birthday = formValues.year + '-' + formValues.month + '-' + formValues.day;
}
</script>

<template>
    <h2 class="title">Talk about yourself</h2>
    <div class="mt-1 text-gray-600">We need your profile data to schedule a doctor meeting for you.</div>
    <form @submit="onSubmit" @input="clearFormError()" class="grid gap-7 mt-10" ref="form-ref">
        <FormField v-slot="{ componentField }" name="name">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="name">First name</Label>
                        <Input id="name" type="text" v-bind="componentField" />
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="family">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="family">Last name</Label>
                        <Input id="family" type="text" v-bind="componentField" />
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="gender" :validate-on-model-update="true">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="gender">Sex assigned at birth</Label>
                        <Select id="gender" v-bind="componentField">
                            <SelectTrigger>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="M">Male</SelectItem>
                                <SelectItem value="F">Female</SelectItem>
                                <SelectItem value="I">Intersex</SelectItem>
                                <SelectItem value="Not">Prefer not to say</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <div class="grid gap-1.5">
            <Label>Date of birth</Label>
            <DateOfBirth />
        </div>
        <Button type="submit" size="lg" class="font-bold w-full mt-4 text-lg" :disabled="loader">
            <Loader2 v-if="loader" class="w-4 h-4 mr-2 animate-spin" />
            Continue
            <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
        </Button>
        <FormError v-if="formError" show-back-to-top />
    </form>
</template>
