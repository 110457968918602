<script setup lang="ts">
import { ref, watch, onActivated } from 'vue';
import { store } from '@/store/store';
import { Card, CardContent } from '@/components/ui/card';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { Loader2 } from 'lucide-vue-next';
import { useToast } from '@/components/ui/toast/use-toast';
import { clearLocalStorage, convertServerDateToLocalDate, delay, getParam } from '@/lib/utils';

type TimeSlotsMap = Record<string, TimeSlot & { time: string }>;

const { toast } = useToast();
const loader = ref(true);
const router = useRouter();
const route = useRoute();
const watchFlag = ref(false);
const selectableDays = ref([] as any);
const morningHours = ref<TimeSlotsMap>({});
const afternoonHours = ref<TimeSlotsMap>({});
const activeTimeSlot = ref('')
const isRescheduling = ref(false);

function rehydrateAppointmentInfo() {
  // get appointment and doctor from local storage if not in store
  if (!store.appointment.pid) {
    let storageApp = localStorage.getItem('appointment');
    let storageDoctor = localStorage.getItem('doctor');
    if (storageApp) {
      store.appointment = JSON.parse(storageApp);
      if (storageDoctor) {
        store.selectedDoctor = JSON.parse(storageDoctor);
      }
    }
  }
}

rehydrateAppointmentInfo();

// we have reviewed this
function setSelectableDays(days: string[]) {
  selectableDays.value = days.map((day) => {
    const date = convertServerDateToLocalDate(`${day} 00:00:00`);
    return {
      day,
      date: date.toLocaleString('en-US', { day: 'numeric' }),
      name: date.toLocaleString('en-US', { weekday: 'short' }),
      month: date.toLocaleString('en-US', { month: 'numeric' }),
      month_name: date.toLocaleString('en-US', { month: 'short' }),
      year: date.toLocaleString('en-US', { year: 'numeric' }),
    };
  });
}

function setupTimes() {
  let days: string[] = [];

  if (userHasSelectedADoctor()) {
    days = store.selectedDoctor.days;
  } else {
    days = store.clinic.days;
  }

  if (!store.appointment.day) {
    store.appointment.day = days[0];
  }

  setSelectableDays(days);
  getTimes();
}

async function getTimes() {
  loader.value = true;

  const { day } = store.appointment;

  morningHours.value = {};
  afternoonHours.value = {};

  const params = {
    day,
    cid: store.clinic.id,
  };

  let res;

  if (userHasSelectedADoctor()) {
    res = await axios.post('/getDoctorTimes', {
      ...params,
      doctorID: store.selectedDoctor.id,
    });
  } else {
    res = await axios.post('/getTimes', params);
  }

  if (res.status == 'success') {
    const responseIsForTheCurrentDay = store.appointment.day == day;

    if (responseIsForTheCurrentDay) {
      await manageTime(transformTimeSlots(res.data));
      loader.value = false;
    }
  }
}

interface TimeSlot {
  doctorID: string;
  price: number;
  currency: string;
  day: string;
}

function transformTimeSlots(data: any) {
  const output: Array<TimeSlot & { time: string }> = [];

  Object.entries(data).forEach(([key, value]) => {
    let defaultDoctorInfo: TimeSlot = {
      doctorID: store.selectedDoctor.id,
      price: store.selectedDoctor.price.price,
      currency: store.selectedDoctor.price.currency,
      day: store.appointment.day,
    };

    if (value && typeof value == 'object') {
      defaultDoctorInfo = Object.values(value)[0];
    }

    output.push({
      ...defaultDoctorInfo,
      time: key,
    });
  });

  return output;
}

async function manageTime(timeSlots: ReturnType<typeof transformTimeSlots>) {
  const formatTimePart = (part: number) => {
    return part.toString().padStart(2, '0');
  };

  const bucketTimeSlots = (
    key: string
  ): { bucketedTime: string; hour: number } => {
    const [hour, minute] = key.split(':').map(Number);

    const minuteBucket = minute - (minute % 15);
    const bucketedTime = `${formatTimePart(hour)}:${formatTimePart(
      minuteBucket
    )}`;

    return { bucketedTime, hour };
  };

  timeSlots.forEach((timeSlot) => {
    const { bucketedTime, hour } = bucketTimeSlots(timeSlot.time);

    const targetHours = hour < 12 ? morningHours.value : afternoonHours.value;
    if (!targetHours[bucketedTime]) {
      targetHours[bucketedTime] = timeSlot;
    }
  });
}

function fillDoctorInfoBasedOnSelectedTime(selectedTime) {
  const doctorInfo =
    morningHours.value[selectedTime] || afternoonHours.value[selectedTime];
  if (doctorInfo) {
    store.selectedDoctor.id = doctorInfo.doctorID;
    store.appointment.doctorID = doctorInfo.doctorID;
    store.selectedDoctor.price = {
      price: doctorInfo.price,
      currency: doctorInfo.currency,
    };
    store.appointment.time = doctorInfo.time;
  }
}

async function chooseTime(selectedTime: string) {
  // prevent sending a request while the previous request is over the air
  if (isRescheduling.value) {
    return;
  }

  activeTimeSlot.value = selectedTime;

  fillDoctorInfoBasedOnSelectedTime(selectedTime);
  localStorage.setItem('appointment', JSON.stringify(store.appointment));

  const isNewAppointment = !localStorage.getItem('reschedule');

  function proceedToNextStep() {
    store.appointment.appID = '';
    store.appointment.save = false;

    const query = {
      doctorName: store.selectedDoctor.name,
      day: store.appointment.day,
    };

    if (store.clinic.type === 'clinic' || store.clinic.type === 'group') {
      router.push({
        name: 'pharmacies',
        query,
        params: { domain: getParam(route) },
      });
      return;
    }
    router.push({ name: 'pay', query, params: { domain: getParam(route) } });
  }

  async function rescheduleAppointment() {
    isRescheduling.value = true;

    const values = {
      doctorID: store.appointment.doctorID,
      time: store.appointment.time,
      day: store.appointment.day,
      appID: store.app.appID,
      pid: store.app.pid,
      save: true,
    };

    const updateAppointmentResponse = await axios.post('/updtAppointment', values) as any;

    loader.value = false;
    isRescheduling.value = false;

    if (updateAppointmentResponse.status !== 'success') {
      toast({
        title: updateAppointmentResponse.msg,
        variant: 'destructive',
      });
      return;
    }

    clearLocalStorage();

    await delay(300);
    router.replace({
      name: 'success',
      query: {
        appID: updateAppointmentResponse.data.appID,
      },
      params: { domain: getParam(route) },
    });
  }

  if (isNewAppointment) {
    proceedToNextStep();
  } else {
    await rescheduleAppointment();
  }
}
function formatTimeLabel(bucket: string, timeSlot: TimeSlot, kind: 'morning' | 'afternoon') {
  const now = new Date()
  
  const todayDateStr = getDateStrFromDate(now)

  if (timeSlot.day === todayDateStr) {
    const [bucketH, minute] = bucket.split(':').map(Number)
    const nowHour = now.getHours()
    const nowMinute = now.getMinutes()

    const isNowInTheBucket = bucketH == nowHour && minute <= nowMinute && minute > nowMinute - 15
    if (isNowInTheBucket) {
      return 'Now'
    }
  }

  if (kind == 'morning') {
    return `${bucket} AM`;
  }

  return `${transformTimeTo12HourFormat(bucket)} PM`;
}

function getDateStrFromDate(date: Date) {
  const [year, month, day] = date.toISOString().split('T')[0].split('-')
  return `${year}-${month}-${day}`
}

function selectDay(day: string, month: string, year: string) {
  activeTimeSlot.value = '';

  store.appointment.day =
    year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
  getTimes();
}

function transformTimeTo12HourFormat(time: string) {
  const hour = Number(time.slice(0, 2));
  const minutes = time.slice(2);
  const displayHour = hour === 12 ? 12 : hour % 12;
  return displayHour.toString().padStart(2, '0') + minutes;
}

onActivated(() => {
  const hasSelectedADoctor = store.clinic.days.length;
  if (hasSelectedADoctor && !watchFlag.value) {
    setupTimes();
  }
});

// we need cid to call doctor times
// we might not have loaded clinic id yet
// after clinic is loaded, get days of that clinic
watch(
  () => store.clinic.days,
  function (val) {
    if (val.length && route.name == 'times' && !selectableDays.value.length) {
      setupTimes();
    }
  }
);

function userHasSelectedADoctor() {
  const hasSelectedADoctor = store.selectedDoctor.id;
  const userHasNoDoctorPreference = !store.selectedDoctor.first_available;
  return hasSelectedADoctor && userHasNoDoctorPreference;
}

watch(
  () => store.selectedDoctor.id,
  function () {
    // we are checking this because due to the usage of keep-alive, this watcher stays active
    // even if the route changes
    if (route.name != 'times') {
      const days = userHasSelectedADoctor()
        ? store.selectedDoctor.days
        : store.clinic.days;

      setSelectableDays(days);

      const defaultSelectedDay = days[0];

      selectDay(defaultSelectedDay.slice(8), defaultSelectedDay.slice(5, 7), defaultSelectedDay.slice(0, 4));

      watchFlag.value = true;
    }
  }
);
</script>

<template>
  <div class="relative">
    <img
      v-if="store.selectedDoctor.image && store.selectedDoctor.image.length"
      :src="store.selectedDoctor.image"
      :alt="store.selectedDoctor.name"
      class="object-cover aspect-square w-32 h-32 rounded-full mb-3 border border-gray-300"
    />
    <h2 class="title relative z-[100]">When would you like to schedule?</h2>
    <div class="text-gray-900 font-bold text-xl shrink-0 mt-10">
      Select Date
    </div>
    <swiper
      slides-per-view="auto"
      :space-between="8"
      :initial-slide="0"
      :mousewheel="true"
      class="mt-3"
    >
      <swiper-slide
        v-for="(day) in selectableDays"
        :key="day.date"
        @click="selectDay(day.date, day.month, day.year)"
        class="cursor-pointer"
      >
        <Card
          :class="{
            'border-2 border-primary bg-card-active':
              day.day === store.appointment.day,
          }"
        >
          <CardContent
            class="flex aspect-square items-center justify-center p-2"
          >
            <div class="text-center">
              <div class="text-md font-bold text-gray-950">
                {{ day.date + ' ' + day.month_name }}
              </div>
              <div class="font-light text-sm text-gray-600 mt-1">
                {{ day.date === getDateStrFromDate(new Date()) ? 'Today' : day.name }}
              </div>
            </div>
          </CardContent>
        </Card>
      </swiper-slide>
    </swiper>
    <div class="mt-8">
      <div class="text-gray-900 font-bold text-xl">Select Time</div>
      <div
        v-if="Object.keys(morningHours).length"
        class="my-3 text-sm text-gray-400"
      >
        Morning
      </div>
      <div class="times grid grid-cols-4 gap-3">
        <Card
          v-for="item in Object.keys(morningHours)"
          class="text-center text-sm font-medium text-gray-900 py-3 px-2 cursor-pointer"
          :class="{ 'border-2 border-primary bg-card-active': activeTimeSlot == item }"
          @click="chooseTime(item)"
          >{{ formatTimeLabel(item, morningHours[item], 'morning') }}</Card
        >
      </div>
      <div
        v-if="Object.keys(afternoonHours).length"
        class="my-3 text-sm text-gray-400"
      >
        Afternoon
      </div>
      <div class="times grid grid-cols-4 gap-3">
        <Card
          v-for="item in Object.keys(afternoonHours)"
          class="text-center text-sm font-medium text-gray-900 py-3 px-2 cursor-pointer"
          :class="{
            'border-2 border-primary bg-card-active': activeTimeSlot == item,
          }"
          @click="chooseTime(item)"
          >{{ formatTimeLabel(item, afternoonHours[item], 'afternoon') }}</Card
        >
      </div>
      <Loader2 v-if="loader" class="w-10 h-10 mx-auto mt-10 animate-spin" />
      <div
        v-if="
          !Object.keys(morningHours).length &&
          !Object.keys(afternoonHours).length &&
          !loader
        "
        class="mt-3 text-sm font-medium text-gray-700"
      >
        Unfortunately, no available times for this day;slots filled recently.
        Please try another day.
      </div>
    </div>
  </div>
</template>

<style>
@media (max-width: 405px) {
  .times > div {
    font-size: 0.75rem;
    padding-left: 4px;
    padding-right: 4px;
  }
}
.swiper-slide {
  width: 78px !important;
}
</style>
