<script setup lang="ts">
import { computed } from 'vue';

const { text, variant = 'bold', class: className } = defineProps<{
  text: string;
  variant?: 'bold' | 'extra-bold';
  class?: string;
}>();

const boldClassName = computed(() => {
  if (variant === 'extra-bold') {
    return 'font-extrabold';
  }
  return 'font-bold';
});

const formattedText = computed(() => text.replace(/\*\*(.*?)\*\*/g, `<b class="${boldClassName.value}">$1</b>`));
</script>

<template>
  <div v-html="formattedText" :class="className"></div>
</template>
