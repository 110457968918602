<script lang="ts" setup>
import { cn } from '@/lib/utils';
import { computed } from 'vue';
import { ArrowUp } from 'lucide-vue-next';
import Boldable from '../shared/Boldable.vue';

const { class: className, showBackToTop = false } = defineProps<{
  class?: string,
  showBackToTop?: boolean
}>()

const mergedClassNames = computed(() => cn(
  'flex items-center justify-center gap-2 flex-col text-left',
  `p-3 rounded-xl bg-red-300 text-red-950 font-medium text-sm`,
  showBackToTop ? 'cursor-pointer' : '',
  className
))

const handleBackToTop = () => {
  if (showBackToTop) {
    // a better implementation would be to scroll to the top of the form
    // but this is good enough for now
    window.scrollTo({ top: 90, behavior: 'smooth' })
  }
}
</script>

<template>
  <div :class="mergedClassNames" @click="handleBackToTop">
    <slot>
      <div class="flex items-center gap-2">
        <Boldable text="**Please review the form** - some fields need your attention to proceed." />
      </div>
    </slot>
    <span v-if="showBackToTop"
      class="cursor-pointer flex mt-2 grow-0 shrink-0 gap-1 bg-red-300 text-red-950 items-center justify-center text-center border-t-2 w-full pt-3 border-red-950/20 border-dotted text-xs font-semibold">
      <span class="opacity-70">Tap here to</span>
      <span class="text-balance flex gap-2 items-center">
        Back to Top
      </span>
      <ArrowUp class="w-4 h-4"></ArrowUp>
    </span>
  </div>
</template>
