import { reactive } from 'vue'
import { update as updateIntercom, shutdown as shutdownIntercom } from '@intercom/messenger-js-sdk'
import { addIntercomAppointmentParams } from '@/lib/utils';

interface Patient {
    pid: string;
    name: string;
    family: string;
}

export const store = reactive({
    support_phone: '(604) 484-0637',
    clinic: {
        id: '',
        type: '',
        name: '',
        firstAvailableTime: '',
        logo: '',
        days: [] as string[]
    },
    uniq: '',
    loginFlag: false,
    phone: '',
    members: [] as any,
    link: '',
    selectedPatient: {
        pid: '',
        name: '',
        family: ''
    } as Patient,
    setSelectedPatient(patient: Patient) {
        this.selectedPatient = patient;

        const patientIdExists = this.selectedPatient.pid !== '';

        const bookedAppointment = this.app;
        const patientWantsToBookANewAppointment = (
            // if they're not editing an appointment, bookedAppointment will be empty
            // and it means we are booking a new appointment
            !bookedAppointment?.appID ||
            // they might've gone through the editing process, but changed the patient.
            // in this case, we need to reset the intercom params.
            bookedAppointment?.pid !== this.selectedPatient.pid
        );

        if (
            patientIdExists &&
            patientWantsToBookANewAppointment
        ) {
            updateIntercom({
                patient_id: this.selectedPatient.pid,
                ...addIntercomAppointmentParams(null),
            })
        }
    },
    newPatient: {
        name: '',
        family: '',
        gender: '',
        birthday: '',
        province: '',
        address: '',
        city: '',
        postal_code: '',
        phone: ''
    },
    selectedDoctor: {
        id: '',
        image: '',
        name: '',
        days: [] as string[],
        price: {} as any,
        first_available: true
    },
    appointment: {
        pid: '',
        doctorID: '',
        time: '',
        day: '',
        note: '',
        file: '' as any,
        duration: 5,
        cid: '',
        clinicID: '',
        doFrom: 'patient',
        bookChannel: 'online_booking',
        phnType: '',
        preferredPharmacy: {
            clinicID: '',
            clinicName: '',
            clinicCity: '',
            clinicFax: '',
            clinicAddress: '',
            clinicPostal: '',
        },
        diseases: '',
        source: '',
        hin: '',
        birthday: '',
        appID: '',
        save: false
    },
    app: {} as any,
    setBookedApp(app: any) {
        this.app = app;

        if (this.app) {
            const { pid, appID } = this.app;

            updateIntercom({
                patient_id: pid,
                ...addIntercomAppointmentParams(appID),
            });
        }
    },
    login() {
        this.loginFlag = true;

        if (this.members.length > 0) {
            // ideally, if the members list is empty, we should try and fetch the list
            // however, for now, we assume that whenever `login` is called, the list is fetched

            // by contract, the first member is the `main` patient who has logged into the app
            const { pid, name, family } = this.members[0];
            updateIntercom({
                name: `${name} ${family}`,
                phone: this.phone,
                user_id: pid,
            })
        }
    },
    logout() {
        this.loginFlag = false;
        shutdownIntercom();
    },
    doctor_phone: '(604) 762-0537',
    redirect_page: {
        fullPath: '',
        name: '',
        query: {} as any
    },
})
