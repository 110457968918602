<script setup lang="ts">
import Address from '@/components/address/Address.vue'
import { schema as addressSchema } from '@/components/address/schema'
import DateOfBirth from '@/components/date-of-birth/DateOfBirth.vue'
import { schema as dateOfBirthSchema } from '@/components/date-of-birth/schema'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { useToast } from '@/components/ui/toast/use-toast'
import { clearLocalStorage, getParam, updateAxiosAuthHeader } from '@/lib/utils'
import { store } from '@/store/store'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { toTypedSchema } from '@vee-validate/zod'
import axios from 'axios'
import { Loader2 } from 'lucide-vue-next'
import { useForm } from 'vee-validate'
import { onActivated, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import * as z from 'zod'
import { FormError, useFormError } from '@/components/form-error'

const { toast } = useToast()

const route = useRoute();
const router = useRouter();

const sameAddress = ref(false)
const loader = ref(false)

const formSchema = toTypedSchema(z.object({
    name: z.string().min(2, { message: 'Minimum 2 characters.' }),
    family: z.string().min(2, { message: 'Minimum 2 characters.' }),
    gender: z.string(),
    relationship: z.string(),
    terms: z.boolean().refine(val => val === true, { message: 'Please accept the terms to continue.' }),
})
    .merge(dateOfBirthSchema)
    .merge(addressSchema)
    .refine(({ country, postal_code }) => {
        if (country === 'ca') {
            return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postal_code);
        } else if (country === 'us') {
            return /^\d{5}(-\d{4})?$/.test(postal_code);
        }
        return true;
    }, { message: "Invalid postal/ZIP code format", path: ['postal_code'] }))

const { handleSubmit, resetForm, setValues } = useForm({
    validationSchema: formSchema,
    initialValues: {
        country: 'ca',
        province: 'BC',
        address: '',
        city: '',
        postal_code: '',
        terms: false
    }
})

const { formError, setFormError, clearFormError } = useFormError()

const onSubmit = handleSubmit((formValues) => {

    if (!loader.value) {

        let values = {
            name: formValues.name,
            address: formValues.address,
            family: formValues.family,
            gender: formValues.gender,
            birthday: formValues.year + '-' + formValues.month + '-' + formValues.day,
            relationship: formValues.relationship,
            province: formValues.province,
            city: formValues.city,
            postal_code: formValues.postal_code,
            phone: store.phone || localStorage.getItem('phone')
        };

        loader.value = true;
        axios.post('/addPatient', values).then((patientInfoResponse: any) => {

            if (patientInfoResponse.status == 'success') {
                const newlyAddedPatient = patientInfoResponse.data;

                const params = {
                    pid: newlyAddedPatient.pid
                };

                axios.post('/refreshToken', params).then((tokenResponse: any) => {

                    if (tokenResponse.status == 'success') {
                        const newToken = tokenResponse.data.token;
                        localStorage.setItem('token', newToken);
                        updateAxiosAuthHeader(newToken);
                        
                        store.setSelectedPatient(newlyAddedPatient);
                        store.appointment.pid = newlyAddedPatient.pid;
                        store.members = [
                            // make sure the patient is not already in the list
                            ...store.members.filter(member => member.pid !== newlyAddedPatient.pid),
                            newlyAddedPatient,
                        ]

                        store.login();
                        clearLocalStorage();

                        setTimeout(function () {
                            loader.value = false
                            router.push({ name: 'details', params: { domain: getParam(route) } })
                        }, 300);

                    }

                });

            } else {

                loader.value = false
                toast({
                    title: patientInfoResponse.msg,
                    variant: 'destructive'
                })

            }
        });

    }


}, (errors) => setFormError(errors.toString()));


watch(sameAddress, function (val) {
    if (val) {
        axios.post('getAddressByPid', { pid: store.selectedPatient.pid }).then((res: any) => {
            if (res.status == 'success') {
                const info = res.data

                if (info.province.length == 2) {
                    info.province = info.province.toUpperCase();
                }

                setValues({
                    province: info.province,
                    address: info.patientAddress,
                    city: info.city,
                    postal_code: info.postal
                }, false);
            }
        })
    }
})

onActivated(() => {
    resetForm();
    sameAddress.value = false;
});
</script>

<template>
    <h2 class="title">Who would you like to add?</h2>
    <form @submit="onSubmit" @input="clearFormError()" class="grid gap-7 mt-10 mb-16">
        <FormField v-slot="{ componentField }" name="name">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="name">First name</Label>
                        <Input id="name" type="text" v-bind="componentField" />
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="family">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="family">Last name</Label>
                        <Input id="family" type="text" v-bind="componentField" />
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="gender" :validate-on-model-update="true">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="grid gap-1.5">
                        <Label for="gender">Sex assigned at birth</Label>
                        <Select id="gender" v-bind="componentField">
                            <SelectTrigger>
                                <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="M">Male</SelectItem>
                                <SelectItem value="F">Female</SelectItem>
                                <SelectItem value="I">Intersex</SelectItem>
                                <SelectItem value="Not">Prefer not to say</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <div class="grid gap-1.5">
            <Label>Date of birth</Label>
            <DateOfBirth />
        </div>
        <div class="grid gap-1.5">
        <FormField v-slot="{ componentField }" name="relationship" :validate-on-model-update="true">
            <FormItem v-auto-animate>
                <FormControl>
            <Label for="relationship">Relationship to you</Label>
            <Select id="relationship" class="w-full" v-bind="componentField">
                <SelectTrigger>
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="spouse">Spouse</SelectItem>
                    <SelectItem value="child">Child</SelectItem>
                    <SelectItem value="sibling">Sibling</SelectItem>
                    <SelectItem value="parent">Parent</SelectItem>
                    <SelectItem value="grandparent">Grandparent</SelectItem>
                    <SelectItem value="other">Other</SelectItem>
                </SelectContent>
            </Select>
        </FormControl>
        <FormMessage />
    </FormItem>
</FormField>
        </div>
        <div class="items-top flex space-x-2">
            <Checkbox id="same_address" v-model:checked="sameAddress" />
            <Label for="same_address">Same address with my address</Label>
        </div>
        <div class="grid gap-7">
            <Address />
        </div>
        <FormField v-slot="{ value, handleChange }" type="checkbox" name="terms">
            <FormItem v-auto-animate>
                <FormControl>
                    <div class="items-top flex space-x-2">
                        <Checkbox id="terms" :checked="value" @update:checked="handleChange" />
                        <Label for="terms" class="leading-snug">
                            <p>By Adding, I certify that I am the legal guardian, power of attorney, representative of the
                                custodian, or have another form of legal authority, such as express consent, for medical decisions
                                for this patient.</p>
                            <p class="mt-3">This includes the authority to view health records for this patient and assist them with
                                accessing healthcare.
                                In addition, I certify that this patient will be present with me during any consultation on their
                                behalf with an Authorized Provider.</p>
                        </Label>
                    </div>
                </FormControl>
                <FormMessage />
            </FormItem>
        </FormField>
        <Button type="submit" size="lg" class="font-bold w-full mt-2 text-lg" :disabled="loader">
            <Loader2 v-if="loader" class="w-4 h-4 mr-2 animate-spin" />
            Add
            <i class="isax isax-arrow-right-1 text-2xl ml-1"></i>
        </Button>
        <FormError v-if="formError" show-back-to-top />
    </form>
</template>
