import { enableIntercom } from "@/store/features"
import Intercom, { shutdown } from "@intercom/messenger-js-sdk"
import { watch } from "vue"

export function useIntercom() {
  const intercomAppId = import.meta.env.VITE_INTERCOM_APP_ID;
  
  watch(enableIntercom, (newVal) => {
    if (newVal && intercomAppId && intercomAppId !== '') {
      Intercom({
        app_id: intercomAppId
      })
    } else {
      shutdown();
    }
  }, { immediate: true })
}