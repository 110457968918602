<script lang="ts" setup>
import { cn } from '@/lib/utils';
import { show } from '@intercom/messenger-js-sdk';

const { label = 'Chat with support', className } = defineProps<{
  label?: string
  className?: string,
}>();

const mergedClassName = cn('text-sm ml-1 underline text-secondary cursor-pointer', className);

function openChatWidget() {
    show();
}
</script>

<template>
  <span @click="openChatWidget" :class="mergedClassName">{{ label }}</span>
</template>
