import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import routes from './router/routes'
import './assets/index.css'
import './assets/iconsax-css/style.css'
import './assets/general.css'
import App from './App.vue'
import axios from 'axios'
import { configure } from 'vee-validate'
import { VueReCaptcha } from 'vue-recaptcha-v3';
import * as Sentry from "@sentry/vue";
import { enableIntercom, enableAveeLogo, enableCurvedButton, enableSwitchingUser, theme } from './store/features'

if (typeof console.table !== 'undefined') {
    console.table({
        deployment_id: __DEPLOYMENT_ID__,
        deployment_time: __DEPLOYMENT_TIME__,
    });
}

axios.defaults.baseURL = import.meta.env.VITE_API_URL;

const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers['authorization'] = 'Bearer ' + token;
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app')?.scrollIntoView({ behavior: 'smooth' });
    },
    
});

router.beforeEach((to, from, next) => {
    const shouldChangeSettings = to.params && !!to.params.domain && to.params.domain !== from.params?.domain;
    if (shouldChangeSettings) {
        const domain = to.params.domain;
        const pocketPillsDomain = import.meta.env.VITE_DOMAIN_POCKET_PILLS;
        
        if (!!pocketPillsDomain && domain === pocketPillsDomain) {
            theme.value = 'pocket-pills'
            enableSwitchingUser.value = false;
            enableAveeLogo.value = false;
            enableCurvedButton.value = false;
            enableIntercom.value = false;
        } else {
            // technically, we should not do this. but, considering our current router setup
            // if we don't do this (and enable intercom by default), there would be a flash on subdomains
            // where the intercom widget should be disabled.
            enableIntercom.value = true;
        }
    }
    next();
});

axios.interceptors.response.use(
    (response) => {
        if (!!import.meta.env.VITE_SENTRY_DSN) {
          if (response.data?.status === 'error') {
            Sentry.captureException(
              new Error(`API Error: ${response.config.url}`),
              {
                extra: {
                  response: JSON.stringify(response.data),
                },
              }
            );
          } else if (!response.data?.status) {
            Sentry.captureException(
              new Error(`API Missing Status: ${response.config.url}`),
              {
                extra: {
                  response: JSON.stringify(response.data),
                },
              }
            );
          }
        }
        return response.data
    },
    (error) => {
        if (!!import.meta.env.VITE_SENTRY_DSN) {
            const options = !!error.config ? { extra: { config: error.config } } : {};
            Sentry.captureException(error, options);
        }
        if (error?.response?.status === 404) {
            router.push({name: 'NotFound' })
        }
    }
)

configure({
    validateOnChange: false,
    validateOnModelUpdate: false
})

const app = createApp(App);

if (!!import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
        ],
    });
}

app.use(router).use(VueReCaptcha, {
    siteKey: import.meta.env.VITE_RECAPTCHASITEKEY,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
        renderParameters: {
            badge: 'bottomleft'
        },
    },
}).mount('#app');
